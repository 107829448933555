.keys__tab{

    .ant-table-cell > span{
        margin-right: 10px;
        height: 40px;
        line-height: 40px;
    }

    th{
        white-space: nowrap;
    }

    th:nth-child(3), th:nth-child(4){
        width: 50%;
    }

    button{
        width: 40px;
        height: 40px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 10px;
        float: right;
    }

    /**.ant-table-thead th{
        border-radius: 5px !important;
    }**/
}