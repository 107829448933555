header{
    background-color: #fff !important;
    box-shadow: 0 2px 8px #f0f1f2;
    z-index: 1;
    padding: 16px 24px !important;
    display: flex;
    align-items: center;
    justify-content: end;

    button{
        margin: 0 !important;
    }
}

.tab
{
    &__menu
    {
        border-top: 1px solid #F5F5F5;
        background-color: #fff;
        padding: 24px;

        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: center;

        button
        {
            &:nth-of-type(1)
            {
                margin-left: auto;
            }
            margin-right: 5px;
            border-radius: 4px;
        }

        label
        {
            margin-left: auto;
        }
    }

    &__title
    {
        font-family: 'Cera Pro';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 22px;
        color: #000 !important;
    }
}

label{
    font-weight: bold !important;
}

.ant-modal-title{
    text-align: center;
    border-bottom: 1px solid #d9d9d9;
}

.ant-modal-footer{
    padding-top: 10px;
    border-top: 1px solid #d9d9d9;
}