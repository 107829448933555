*
{
    color: #595959 !important;
}

.ant-layout-sider
{
    background-color: #fff !important;
    overflow: hidden;
    max-width: 0 !important;
    width: 0 !important;
    //box-shadow: 0 2px 8px 0 rgba(0,0,0,0.15);
    border-right: 1px solid #e4e9f0;
}

.ant-menu-item{
    margin: 0 !important;
    width: 100% !important;
}

.ant-menu-item-selected {
    background-color: #E6F7FF !important;
    margin: 0 !important;
    border-right: 4px solid #1890FF;
    width: 100% !important;
}

.ant-menu-item-selected > span, .ant-menu-item-selected svg *{
    color: #1890FF !important;
}

ul
{
    background-color: transparent !important;
}

li
{
    border-radius: 0 !important;
}