.shine {
    background-image: linear-gradient(270deg, rgba(100, 181, 239, 0) 48.44%, #64b5ef 75.52%, rgba(100, 181, 239, 0) 100%);
    background-repeat: no-repeat;
    animation: bg-move linear 5s infinite;
}

a path{
    fill: white !important;
}

@-webkit-keyframes bg-move {
    0%   { background-position: -500px 0; }
    100% { background-position: 1000px 0; }
}
@keyframes bg-move {
    0%   { background-position: -500px 0; }
    100% { background-position: 1000px 0; }
}

@media screen and (max-width: 480px) {
    .logo {
        width: 110px !important;
        height: 110px !important;
        border-radius: 55px !important;
    }
    .content {
        padding: 16px !important;
    }
    .mobile{
        display: block !important;
    }
}